import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAccount, useChainId } from "wagmi";
// import { Toaster } from "react-hot-toast";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { checkUser, registerUser } from "./web3/getWeb3";
import { localhost_api } from "./web3/config";
import { duration } from "moment";
import Footer from "./Footer";
// import { GoArrowUpRight } from "react-icons/go";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { FaLongArrowAltDown } from "react-icons/fa";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const Registration = () => {
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [wallet, setWallet] = useState();
  const [referral, setReferral] = useState();
  const [phone, setPhone] = useState("");
  const { address, connector, chainId } = useAccount();
  const chain = useChainId();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [stake, setStake] = useState();
  const [ins, setIns] = useState();
  const [userType, setUserType] = useState(0);
  const [apiuserType, setapiUserType] = useState(0);
  const [email, setEmail] = useState();
  const [isShow, setIsShow] = useState(false);
  const [alreadyInSignup, setAlreadyInSignup] = useState(false);
  const [userInReg, setAlreadyInRegTable] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (value) => {
    setPhone(value);
    console.log(value);
  };

  const getUserType = async (ref) => {
    try {
      const resUser = await axios.get(localhost_api + "idToAddress", {
        params: {
          userId: ref,
        },
      });
      const apiref = resUser.data.data?.usertype;
      setapiUserType(apiref);
      // console.log(apiref, ":::::1234");
    } catch (error) {
      console.log(error);
      setUserType(0);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("refID")) {
      const refIDValue = params.get("refID");
      setReferral(refIDValue?.trim());
      getUserType(refIDValue);
    } else {
    }

    if (params.has("stake")) {
      setStake(true);
    }
    if (params.has("insurance")) {
      setIns(true);
    }
  }, [location.search]);

  const { id } = useParams();

  useEffect(() => {
    console.log(location.search, location);
  }, [id]);

  useEffect(() => {
    if (address) {
      const formattedAddress = address.slice(0, 5) + "..." + address.slice(-8);
      setWallet(formattedAddress);
    } else {
      setWallet("Connect Your Wallet");
    }
  }, [address]);

  async function validateReferral(user, ref) {
    try {
      const resp = await axios.post(localhost_api + "registerValidate", {
        user: user,
        ref: ref,
      });
      return resp.data;
    } catch (e) {
      console.log(e, "error in register's checkuser");
      return false;
    }
  }

  async function checkInSignup() {
    try {
      const resp = await axios.get(localhost_api + "signup-dataCheck", {
        params: {
          user: address,
        },
      });
      const d = await checkUserRegTable();
      if (resp.data.status == 200 && d) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }

      if (resp.data.status == 200) {
        setAlreadyInSignup(true);
      }
      if (d) {
        setAlreadyInRegTable(true);
      }
      console.log(resp, ":::::resp", resp.data.data.usertype);
    } catch (error) {
      console.log(error);
    }
  }
  async function checkUserRegTable() {
    try {
      const data = await axios.get(localhost_api + "checkuser-regester", {
        params: {
          user: address,
        },
      });
      if (data.data.status == 200) {
        setAlreadyInRegTable(true);
        return true;
      } else {
        setAlreadyInRegTable(false);
        return false;
      }
    } catch (error) {
      console.log(error);
      setAlreadyInRegTable(false);
      return false;
    }
  }

  useEffect(() => {
    if (address) {
      checkInSignup();
      // checkUserRegTable()
    }
  }, [address]);

  const register = async () => {
    handleCloseModal();

    if (!name || !address || !referral || !phone || !userType || !email) {
      toast.error("Please fill in all fields.");
      return;
    }
    console.log("step 1 ");
    try {
      let dataValidate;
      setLoader(true);
      var toastId = toast.success(
        "Registration In Progress. It will take sometime... Please Wait",
        {
          duration: Infinity,
        }
      );
      await checkUserRegTable();
      const resUser = await axios.get(localhost_api + "idToAddress", {
        params: {
          userId: referral,
        },
      });
      let userExist;
      let apiref;
      console.log("step 2 ");
      if (resUser.data.status == 200) {
        apiref = resUser.data.data?.user;
      } else {
        setLoader(false);
        toast.dismiss(toastId);
        toast.error("Invalid Referral code");
        return;
      }
      console.log("step 3 ");
      let signupRes;

      signupRes = await axios.post(localhost_api + "signup", {
        name: name,
        address: address,
        referrerId: referral,
        phone: phone,
        usertype: userType,
        email: email,
      });
      if (signupRes?.data.status == 201) {
        console.log("step 4 ");
        setLoader(false);
        toast.dismiss(toastId);
        toast.success("Registration SuccessFull!");
      }
      if (signupRes?.data.status == 400) {
        console.log("step 10 ");
        setLoader(false);
        toast.dismiss(toastId);
        console.log("step 5 ", signupRes);
        toast.error(signupRes.data.message);
        return;
      }

      // console.log("step 4 ");
      // console.log(alreadyInSignup, callContract, "step 8");
      // if (alreadyInSignup || callContract) {
      //   console.log("step 7");

      //   if (chain == "303") {
      //     dataValidate = await validateReferral(address, apiref);
      //     userExist = await checkUser(address);
      //     if (userExist && alreadyInSignup) {
      //       setLoader(false);
      //       toast.dismiss(toastId);
      //       toast.error("You are already registered");
      //     }
      //   } else {
      //     userExist = false;
      //   }
      //   if (!userExist && !userInReg) {
      //     console.log("step 6 ");
      //     let register = await registerUser(
      //       address,
      //       apiref,
      //       chain,
      //       dataValidate?.data
      //     );

      //     if (register) {
      //       setLoader(false);
      //       toast.dismiss(toastId);
      //       toast.success("Registration Successful");
      //       setIsShow(true);
      //     } else {
      //       setLoader(false);
      //       toast.dismiss(toastId);
      //       toast.error("Registration failed");
      //     }
      //     console.log(register, "register");
      //   } else {
      //     setLoader(false);
      //     toast.dismiss(toastId);
      //     toast.error("You are Already Registered in contract");
      //   }
      // }
    } catch (err) {
      toast.dismiss(toastId);
      setLoader(false);
      console.log(err, "res form register");
    }
  };

  const traderApi = async () => {
    try {
      if (!address) {
        toast.error("Plese connect wallet");
        return;
      }
      const response = await axios.get(localhost_api + "dashboard", {
        params: {
          user: address,
        },
      });
      const dashboard = response.data.data;
      if (!dashboard.user) {
        toast.error("Please wait !");
      }
      window.open(
        `https://trader.wyscale.com/m/1j.aspx?uid=${dashboard.userId}&wallet=${dashboard.user}`,
        "_self"
      );
    } catch (error) {
      console.log(error, "from traderApi");
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <div
        className="row col-lg-12 "
        style={{ background: "url(image/banner.png)", padding: "10px" }}
      >
        <div
          className="col-lg-4  form-card "
          style={{ margin: "90px auto 0px" }}
        >
          <div class="col-lg-12">
            <h5 class="text-gradient " style={{ paddingTop: "30px" }}>
              Let's Get Started
            </h5>
            <p class="p-lg">Enter your details to continue</p>
          </div>
          <div className="col-lg-12">
            <form
              action="
                "
            >
              <div className="col-lg-12 form">
                <h6 style={{ color: "white" }}>Name*</h6>
                <input
                  type="text"
                  placeholder="Name*"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="col-lg-12 form">
                <h6 style={{ color: "white" }}>Email</h6>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <h6 style={{ color: "white" }}>Phone*</h6>
              <div
                className="col-lg-12 form1"
                style={{
                  border: "1px solid rgb(49,49,49)",
                  borderRadius: "80px",
                  background: "trasparent",
                  outline: "none ",
                }}
              >
                <PhoneInput
                  defaultCountry="IN"
                  value={phone}
                  onChange={handlePhoneNumberChange}
                  placeholder="Phone*"
                />
              </div>
              <div className={`col-lg-12 form ${address ? "f1" : ""}`}>
                <h6 style={{ color: "white", marginTop: "10px" }}>Wallet*</h6>
                <input
                  type="text"
                  placeholder="Wallet Adress*"
                  value={wallet}
                  readOnly
                  style={{}}
                />
              </div>
              <div className="col-lg-12 form">
                <h6 style={{ color: "white" }}>Referral*</h6>
                <input
                  type="text"
                  placeholder="Refferral*"
                  value={referral}
                  onChange={(e) => {
                    setReferral(e.target.value);
                    getUserType(e.target.value);
                  }}
                  // readOnly
                />
              </div>
              <div className="col-lg-12 form">
                <h6 style={{ color: "white" }}>User Type *</h6>
                <select
                  className="bg-dark"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setUserType(e.target.value);
                  }}
                  value={userType}
                >
                  <option value={0}>Choose User Type</option>
                  {apiuserType == "consultant" && (
                    <option value="consultant">AGENT</option>
                  )}

                  <option value="client">CLIENT</option>
                </select>
              </div>

              {address ? (
                !isShow ? (
                  <a
                    className="col-lg-12 form-btn"
                    onClick={register}
                    href="javascript:void(0)"
                  >
                    {loader ? (
                      <div
                        className="spinner-border spinner-border-sm mx-1"
                        role="status"
                      ></div>
                    ) : null}
                    Register
                  </a>
                ) : (
                  <a className="col-lg-12 form-btn" href="javascript:void(0)">
                    Invest now <FaLongArrowAltDown />
                  </a>
                )
              ) : (
                <a className="col-lg-12 f" href="javascript:void(0)">
                  <ConnectButton />
                </a>
              )}
            </form>
          </div>

          {isShow && (
            <div className="row  mb-5 col-lg-12 lfd">
              <div
                className="subDiv position-relative "
                onClick={() =>
                  window.open("https://farming.wyscale.com/", "_self")
                }
              >
                <div className="form-btn m-0 p-0">
                  <span>Farming</span>
                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div>

              <div
                className="subDiv position-relative"
                onClick={() =>
                  window.open("https://staking.wyscale.com/?tab=Live", "_self")
                }
              >
                <div className="form-btn m-0 p-0">
                  <span>Staking</span>
                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div>

              <div
                className="subDiv position-relative"
                onClick={() =>
                  window.open("https://gleam.wyscale.com/", "_self")
                }
              >
                <div className="form-btn m-0 p-0">
                  <span>Gleam</span>
                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div>

              {/* <div className="subDiv position-relative ">
                <div className="form-btn m-0 p-0">
                  <span>Bridge</span>

                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div> */}

              <div
                className="subDiv position-relative "
                onClick={() =>
                  window.open("https://wyscale.com/FixedReturns", "_self")
                }
              >
                <div className="form-btn m-0 p-0">
                  <span>LFD</span>

                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div>

              <div
                className="subDiv position-relative "
                onClick={() =>
                  // window.open("https://fx.wyscale.com/site/index.html", "_self")
                  traderApi()
                }
              >
                <div className="form-btn m-0 p-0">
                  <span>CFD</span>
                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div>

              <div
                className="subDiv position-relative "
                onClick={() =>
                  window.open("https://insurance.wyscale.com/", "_self")
                }
              >
                <div className="form-btn m-0 p-0">
                  <span>Insurance</span>

                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div>

              <div
                className="subDiv position-relative "
                onClick={() => toast.success("Coming soon")}
              >
                <div className="form-btn m-0 p-0">
                  <span>HFT</span>

                  <span className="ic">
                    <FaArrowUpRightFromSquare />
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="mb-4">
            <h6 style={{ color: "white" }}>
              Registration Status :{" "}
              {isShow
                ? "Registeration Completed"
                : "Your registration is Pending"}{" "}
            </h6>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Registration;
