import React, { useState, useEffect } from "react";
import { GoArrowDown } from "react-icons/go";
import "@rainbow-me/rainbowkit/styles.css";
import { ConnectButton, useChainModal } from "@rainbow-me/rainbowkit";

import {
  setAllStakes,
  setAlphaId,
  setClaimedReward,
  setTotalStake,
  setWalletId,
} from "./redux/reducer";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { setRefAddress } from "./redux/reducer";
import { idToAddres } from "./web3/getWeb3";
import { disconnect } from "@wagmi/core";
import { useAccount, useNetwork } from "wagmi";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { localhost_api } from "./web3/config";
import { getUserStake } from "./web3/api";
import { NonceTooLowError } from "viem";

const Header = () => {
  const [count, setCount] = useState(true);
  const dispatch = useDispatch();
  const { refAddress } = useSelector((state) => state.wallet);
  const { walletID } = useSelector((state) => state.wallet);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { openChainModal } = useChainModal();

  const [dashboard, setDashboard] = useState(null);
  useEffect(() => {
    const dashboardData = async () => {
      try {
        const response = await axios.get(localhost_api + "dashboard", {
          params: {
            user: walletID,
          },
        });

        if (response.data.data) {
          setDashboard(response.data.data);

          dispatch(setTotalStake(response.data.data.wysStaked / 1e18));
          const claimedReward1 =
            (response.data.data.directBonus + response.data.data.levelBonus) /
            1e18;
          dispatch(setClaimedReward(claimedReward1));
        } else {
          setDashboard(0);
          dispatch(setTotalStake(0));
        }
      } catch (error) {
        // Handle errors here
        console.log("Error fetching data:", error);
      }
    };

    dashboardData();
  }, [walletID]);

  const location = useLocation();
  const [alphaNumericid, setAlphaNumericid] = useState(0);
  useEffect(() => {
    const setReferral = async () => {
      // console.log("address step1",address)
      if (address) {
        try {
          const response = await axios.get(localhost_api + "refreal", {
            params: {
              wallet_add: address,
            },
          });
          if (response.data.status === 200 && response.data.referrerId != "") {
            try {
              const res = await axios.get(localhost_api + "idToAddress", {
                params: {
                  userId: response.data.referrerId,
                },
              });
              dispatch(setRefAddress(res.data.data?.user));
            } catch (err) {
              console.log(err);
            }
          } else {
            const r = await idToAddres(1);
            if (r !== "") {
              dispatch(setRefAddress(r));
              console.log(r, "set date");
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    };

    setReferral();
  }, [address, dispatch]);

  const dropdown = () => {
    if (count == true) {
      setCount(false);
    } else {
      setCount(true);
    }
  };

  useEffect(() => {
    const adressToID = async () => {
      try {
        const response = await axios.get(localhost_api + "addressToId", {
          params: {
            user: address,
          },
        });

        if (response.data.status == 500) {
          dispatch(setAlphaId(0));
        } else {
          dispatch(setAlphaId(response.data.data?.userId));
        }
      } catch (err) {
        console.log(err);
      }
    };
    adressToID(address);
  }, [address]);

  useEffect(() => {
    // console.log("dddddd", alphaNumericid);
  });

  useEffect(() => {
    if (address) {
      getUserStake(address).then((res) => {
        if (res?.status == 200) {
          dispatch(setAllStakes(res.data.data));
        }
      });
    }
  }, [address]);

  return (
    <>
      <div className="row" id="header" style={{ margin: "0px !important" }}>
        <div
          className="row col-lg-12"
          style={{
            height: "40px",
            background: "#171717",
          }}
        >
          <div className="col-lg-11 m-auto" style={{ position: "relative" }}>
            <select
              name=""
              id=""
              style={{
                background: "transparent",
                color: "white",
                outline: "none",
                border: "none",
                display: "inline-block",
              }}
            >
              <option
                value=""
                style={{ background: "transparent", color: "white" }}
              >
                Individual Investor
              </option>
            </select>

            <div
              style={{
                display: "inline-block",
                position: "absolute",
                right: 5,
                color: "white",
              }}
            >
              <span>Download App</span>
              <span>
                <GoArrowDown />
              </span>
            </div>
          </div>
        </div>

        <nav class="navbar navbar-expand-lg bg-body-tertiary top-fluid">
          <div
            class="container-fluid navc"
            style={{ width: "92%", margin: "auto" }}
          >
            <a class="navbar-brand " href="/">
              <img
                src="image/t Text.svg"
                height="60"
                width="100%"
                alt=""
                class="mb-2"
              />
            </a>
            <div
              class="navbar-brand"
              style={{ marginRight: "0px", display: "flex", gap: "10px" }}
            >
              <div>
                <div
                  class="connect-btn-contact"
                  style={{
                    background: "transparent",
                    border: "2px solid #f6e363",
                  }}
                >
                  <div
                    class="connect-content fw-bolder"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <ConnectButton.Custom>
                      {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        authenticationStatus,
                        mounted,
                      }) => {
                        const ready =
                          mounted && authenticationStatus !== "loading";
                        const connected =
                          ready &&
                          account &&
                          chain &&
                          (!authenticationStatus ||
                            authenticationStatus === "authenticated");
                        if (account && account.address !== undefined) {
                          dispatch(setWalletId(account.address));
                        }
                        return (
                          <div
                            {...(!ready && {
                              "aria-hidden": true,
                              style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                              },
                            })}
                          >
                            {(() => {
                              if (!connected) {
                                return (
                                  <div onClick={openConnectModal}>
                                    <span
                                      style={{
                                        background:
                                          "linear-gradient(45deg, #8fd41f 0%, #ffe569 40%)",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                      }}
                                    >
                                      Connect Wallet
                                    </span>
                                  </div>
                                );
                              }

                              if (chain.unsupported) {
                                return (
                                  <div
                                    onClick={openChainModal}
                                    // type="button"
                                    style={{
                                      background:
                                        "linear-gradient(45deg, #8fd41f 0%, #ffe569 40%)",
                                      WebkitBackgroundClip: "text",
                                      WebkitTextFillColor: "transparent",
                                    }}
                                  >
                                    Wrong network
                                  </div>
                                );
                              }

                              return (
                                <div style={{ display: "flex", gap: 12 }}>
                                  <div
                                    style={{
                                      background:
                                        "linear-gradient(45deg, #8fd41f 0%, #ffe569 40%)",
                                      WebkitBackgroundClip: "text",
                                      WebkitTextFillColor: "transparent",
                                      // border: "2px solid #f6e363",
                                    }}
                                    onClick={dropdown}
                                  >
                                    {account.displayName}
                                  </div>
                                </div>
                              );
                            })()}
                          </div>
                        );
                      }}
                    </ConnectButton.Custom>{" "}
                  </div>
                </div>

                <div
                  className="dropdown1"
                  style={{ display: count ? "none" : "block" }}
                >
                  {/* <Link
                    to="/signup"
                    style={{
                      background: "#3a3a3d",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      color: "#fffc",
                      fontWeight: "600",
                    }}
                  >
                    Register
                  </Link> */}
                  {/* <Link to="/portfolio">Portfolio</Link> */}
                  {/* <Link
                    to="/dashboard"
                    style={{ color: "#fffc", fontWeight: "600" }}
                  >
                    Dashboard
                  </Link> */}
                  {/* <Link to="" onClick={openChainModal}>
                    Chain
                  </Link> */}

                  <Link
                    href="javascript:void(0)"
                    onClick={async () => {
                      await disconnect();
                      await dropdown();
                    }}
                    style={{ color: "#fffc", fontWeight: "600" }}
                  >
                    Disconnect
                  </Link>
                </div>
              </div>
              {address && (
                <button
                  onClick={openChainModal}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "transparent",
                    // border:"none",
                    color: "white",
                    // paddingLeft: "3px",
                    // paddingRight: "3px",
                    border: "2px solid #f6e363",
                    // borderRadius: "5px",
                  }}
                  className="connect-btn-contact"
                  type="button"
                >
                  <div
                    style={{
                      width: "24px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <img
                      alt={chain?.name ?? "Chain icon"}
                      src={chain?.iconUrl}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>

                  {/* {chain.name} */}
                </button>
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
