import {
  contract_abi,
  contract_adress,
  Wys_address,
  Wys_abi,
  arb_address,
  arb_abi,
  bnb_address,
  bnb_abi,
  token_abi,
  usdt_address,
  RegistrationAbi,
  bscRegistration,
  polRegistration,
  polAbi,
} from "./config";
import {
  writeContract,
  readContract,
  fetchBalance,
  signMessage,
} from "@wagmi/core";
import { waitForTransaction } from "@wagmi/core";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getInstance = () => {
  return {
    address: contract_adress,
    abi: contract_abi,
  };
};

const wys = () => {
  return {
    address: Wys_address,
    abi: token_abi,
  };
};

const arb = () => {
  return {
    address: arb_address,
    abi: token_abi,
  };
};
const bnb = () => {
  return {
    address: bnb_address,
    abi: token_abi,
  };
};

const usdt = () => {
  return {
    address: usdt_address,
    abi: token_abi,
  };
};

export async function amtApprove(con_address, amt) {
  // console..log(con_address, amt, "amt approve wys");
  try {
    const data = await writeContract({
      ...wys(),
      functionName: "approve",
      args: [con_address, amt],
    });
    const transactionReceipt = await waitForTransaction({
      hash: data.hash,
    });
    // console..log(data, "Transaction receipt", transactionReceipt);
    return transactionReceipt;
  } catch (error) {
    console.error("Error while approving:", error);
    throw error;
  }
}

export async function allowance(walletAdd, con_add) {
  // console..log(walletAdd, con_add, "allowance walletadd, Con ADD");
  const data = await readContract({
    ...wys(),
    functionName: "allowance",
    args: [walletAdd, con_add],
  });
  // console..log(data, "allowance L::::::::::::::");
  return Number(data);
}

export async function usdtApprove(con_address, amt) {
  // console..log(con_address, amt, "amt approve wys");
  try {
    const data = await writeContract({
      ...usdt(),
      functionName: "approve",
      args: [con_address, amt],
    });
    const transactionReceipt = await waitForTransaction({
      hash: data.hash,
    });
    console.log(data, "Transaction receipt", transactionReceipt);
    return transactionReceipt;
  } catch (error) {
    console.error("Error while approving:", error);
    throw error;
  }
}

export async function usdt_allowance(walletAdd, con_add) {
  // console..log(walletAdd, con_add, "allowance walletadd, Con ADD");
  const data = await readContract({
    ...usdt(),
    functionName: "allowance",
    args: [walletAdd, con_add],
  });
  // console..log(data, "allowance L::::::::::::::");
  return Number(data);
}

export async function getPackageToWYSAmt(id) {
  const data = await readContract({
    ...getInstance(),
    functionName: "getPackageToWYSAmt",
    args: [id],
  });
  //   // console..log(hey);
  return data;
}

export async function wysPrice() {
  try {
    const data = await readContract({
      ...getInstance(),
      functionName: "WYSPrice",
      args: [],
    });

    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function idToAddres(id) {
  // // console..log(id, "idddd");
  const data = await readContract({
    address: contract_adress,
    abi: contract_abi,
    functionName: "idToAddress",
    args: [id],
  });

  return data;
}

export async function getWYSToUSDAmt(amt) {
  // console..log(amt, "idddd");
  const data = await readContract({
    address: contract_adress,
    abi: contract_abi,
    functionName: "getWYSToUSDAmt",
    args: [amt],
  });

  return Number(data);
}

export async function stakeWYS(amount, ref, pack) {
  // console.log(amount, ref, pack?.toString(), "ref:::: from stake wys");
  const data = await writeContract({
    ...getInstance(),
    functionName: "stakeWYS",
    args: [
      amount.toLocaleString("fullwide", { useGrouping: false }),
      ref,
      pack?.toString(),
    ],
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });
  return transactionReceipt;
}

export async function toppup(amount) {
  // console.log(amount, "ref:::: from stake wys");
  const data = await writeContract({
    ...getInstance(),
    functionName: "topup",
    args: [amount.toLocaleString("fullwide", { useGrouping: false })],
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });
  return transactionReceipt;
}

export async function getBalanceUser(address, TOKEN_ADDRESS) {
  // console.log(address, TOKEN_ADDRESS, ":::::");
  try {
    const balance = await fetchBalance({
      address: address,
      token: TOKEN_ADDRESS,
    });
    // console.log(balance);
    return balance;
  } catch (err) {
    console.log(err);
  }
}

export async function arbPrice() {
  try {
    const data = await readContract({
      ...getInstance(),
      functionName: "ARBPrice",
      args: [],
    });

    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function arbApprove(con_add, amt) {
  // console..log(amt, "idddd");
  const data = await writeContract({
    ...arb(),
    functionName: "approve",
    args: [con_add, amt],
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });

  return transactionReceipt;
}

export async function arbAllowance(wallet, con) {
  const data = await readContract({
    ...arb(),
    functionName: "allowance",
    args: [wallet, con],
  });
  // console..log(data, "Arb allowance");
  return data;
}

export async function stakeWYSAndARB(amount, ref, pack) {
  // console..log(amount, ref, pack, "ref::::");
  const data = await writeContract({
    ...getInstance(),
    functionName: "stakeWYSAndARB",
    args: [
      amount.toLocaleString("fullwide", { useGrouping: false }),
      ref,
      pack,
    ],
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });

  return transactionReceipt;
  // return data;
}

//bnb
export async function bnbPrice() {
  try {
    const data = await readContract({
      ...getInstance(),
      functionName: "BNBPrice",
      args: [],
    });

    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function wyzPrice() {
  try {
    const data = await readContract({
      ...getInstance(),
      functionName: "WYZPrice",
      args: [],
    });

    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function bnbApprove(con_add, amt) {
  const data = await writeContract({
    ...bnb(),
    functionName: "approve",
    args: [con_add, amt],
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });

  return transactionReceipt;
}

export async function bnbAllowance(wallet, con) {
  // console..log(wallet, con, "web3");
  const data = await readContract({
    ...bnb(),
    functionName: "allowance",
    args: [wallet, con],
  });
  // console..log(data);
  return data;
}

export async function stakeWYSAndBNB(amount, ref, pack) {
  // console..log(amount, ref, pack, "ref::: inside wys:");
  const data = await writeContract({
    ...getInstance(),
    functionName: "stakeWYSAndBNB",
    args: [
      amount.toLocaleString("fullwide", { useGrouping: false }),
      ref,
      pack?.toString(),
    ],
    // value: amount,
  });
  return data;
}

export async function stakeWYSAndWYZ(planId, amount, ref, pack, a, obj) {
  const data = await writeContract({
    ...getInstance(),
    functionName: "stakeWYSAndWYZ",
    args: [
      planId,
      [obj?.totalwys, obj?.totalwyz, obj?.totalAmount],
      ref,
      pack?.toString(),
      obj?.number,
      Number(obj?.signature.v),
      obj?.signature.r,
      obj?.signature.s,
    ],
    value: [obj?.totalwyz],
  });

  return data;
}

export async function stakeUSDt(amt, ref, duration, obj) {
  console.log(
    obj,
    ":: usdt",
    "->>>>>>>>>>>>>>>>>>>>>>>>",
    obj?.planId,
    [obj?.amount1, obj?.wyz, obj?.totalAmountInWei],
    ref,
    obj.duration?.toString(),
    obj?.number,
    Number(obj?.signature.v),
    obj?.signature.r,
    obj?.signature.s
  );
  const data = await writeContract({
    ...getInstance(),
    functionName: "stakeUSDT",
    args: [
      obj?.planId,
      [obj?.amount1, obj?.wyz, obj?.totalAmountInWei],
      ref,
      obj.duration?.toString(),
      obj?.number,
      Number(obj?.signature.v),
      obj?.signature.r,
      obj?.signature.s,
    ],
    value: [0],
  });

  return data;
}

//users

export async function getUsers(walleAdd) {
  const data = await readContract({
    ...getInstance(),
    functionName: "users",
    args: [walleAdd],
    // value: amount,
  });
  return data;
}

// is user Exist
export async function checkUser(walleAdd) {
  try {
    const data = await readContract({
      ...getInstance(),
      functionName: "isUserExists",
      args: [walleAdd],
    });
    console.log(data, "regiter check in contract");
    return data;
  } catch (err) {
    console.log(err, "err from checkUSer");
  }
}

// claim Money

export async function claimwys(walleAdd, planID, duration) {}

export async function claimARB(walleAdd, planID, duration) {}
export async function claimBNB(walleAdd, planID, duration) {}

export async function claimBNBAmt(planID, duration) {
  const data = await writeContract({
    ...getInstance(),
    functionName: "claimRoi",
    args: [planID, duration],
    // value: amount,
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });
  return transactionReceipt;
}

export async function claimARBAmt(planID, duration) {
  const data = await writeContract({
    ...getInstance(),
    functionName: "claimRoi",
    args: [planID, duration],
    // value: amount,
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });
  return transactionReceipt;
}

export async function claimWYSAmt(planID, duration) {
  const data = await writeContract({
    ...getInstance(),
    functionName: "claimRoi",
    args: [planID, duration],
    // value: amount,
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });
  return transactionReceipt;
}

export async function rewardInfo(walleAdd) {
  const data = await readContract({
    ...getInstance(),
    functionName: "rewardInfo",
    args: [walleAdd],
  });
  return data;
}

export async function claimRefereal() {
  const data = await writeContract({
    ...getInstance(),
    functionName: "claim",
    args: [],
    // value: amount,
  });
  const transactionReceipt = await waitForTransaction({
    hash: data.hash,
  });
  return transactionReceipt;
}
//REGISTER
export async function registerUser(userAddress, userRef, chain, d) {
  try {
    console.log("In register function ",chain,d?.v,d?.r,d?.s,userAddress,userRef)
    if (chain == "303") {
      console.log(d, "in sginffasf");
      const data = await writeContract({
        ...getInstance(),
        functionName: "registration",
        args: [userAddress, userRef,d?.signature.v,d?.signature.r,d?.signature.s],
      });
      const transactionReceipt = await waitForTransaction({
        hash: data.hash,
      });
      return transactionReceipt;
    } else if (chain == "56") {
      const data = await writeContract({
        abi: RegistrationAbi,
        address: bscRegistration,
        functionName: "register",
        args: [userRef],
      });
      const transactionReceipt = await waitForTransaction({
        hash: data.hash,
      });
      return transactionReceipt;
    } else if (chain == "137") {
      const data = await writeContract({
        abi: polAbi,
        address: polRegistration,
        functionName: "register",
        args: [userRef],
      });
      const transactionReceipt = await waitForTransaction({
        hash: data.hash,
      });
      return transactionReceipt;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function signClaimMessage(address) {
  try {
    const msg = await signMessage({
      address,
      message: address,
    });
    // console.log(msg);
    return msg;
  } catch (error) {
    console.log(error);
  }
}

// getCurrency

export async function balance(address) {
  const balance1 = await fetchBalance({
    address: address,
  });
  // console.log("ba", balance1);
  return balance1;
}
